.page-title {
	position: relative;
	z-index: 70000;
	padding: 2em 0;
	color: $white;
	border-bottom: 5px solid $white;
	text-shadow: 0 1px 2px rgba(0,0,0,0.4);
	@extend .gdfancybg--primary;

	h1 {
		margin: 0;
		line-height: 1em;
		text-align: center;
		small {
			display: block;
			font-size: 70%;
			font-weight: $font-weight-bold;
			line-height: 1em;
		}
	}
	
	@include media-breakpoint-up(md) {
		h1 { font-size: 2.5rem; }
	}
	
	@include media-breakpoint-up(lg) {
		padding: 7em 0 1.5em;
		h1 { text-align: left; }
	}

	@include media-breakpoint-up(xl) {
		h1 { font-size: 2.75rem; }
	}

	.post & {
		h1 { max-width: 730px; }
	}

}